/*=========================================================================================
    File Name: laravel-menu.scss
    Description: laravel Specific menu styles
    ----------------------------------------------------------------------------------------
    Item name: Vuexy  - Vuejs, HTML & Laravel Admin Dashboard Template
    Author: PIXINVENT
    Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

// Core variables and mixins
@import "bootstrap/functions";
@import "bootstrap/mixins";
@import "bootstrap-extended/mixins";

// Core variables and mixins overrides
@import "core/variables/variables";
@import "bootstrap/variables";

// Overrides user variable
@import "core/variables/components-variables";

// Import first main menu mixin
@import "core/mixins/main-menu-mixin";

//  Vertical menu
//=========================
body.vertical-layout {
   &.vertical-menu-modern {
        .horizontal-menu-wrapper{
            display: none;
        }
    }
}

// Horizontal Menu
//=========================
.horizontal-menu{
    // Hide Vertical menu in Horizontal Layout in Laravel not in HTML
	>.main-menu{
		display: none;
	}
	.fixed-top{
		left: 0;
	}
}

// content style
//=========================
html body.navbar-static .app-content .header-navbar {
    background: transparent;
    box-shadow: none !important;
}

@include media-breakpoint-down(xs) {
    // Vertical Layout
    body.vertical-layout {
        &:not(.horizontal-layout){
            &.navbar-floating{
                .header-navbar{
                    margin-left: 1.2rem;
                    margin-right: 1.2rem;
                }
            }
        }
    }
 }